@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply h-[100%] p-0 m-0;
  }

  .btn:active {
    @apply scale-95 shadow-lg;
  }
}
